/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import logoImg from "../assets/images/logo_black.png";
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

import "./less/product.less";
import {
  message,
  Modal,
  Radio,
} from "antd";

const home1Img = require("../assets/images/presale/H1_BLACK.png");
const home2Img = require("../assets/images/presale/home2.png");
const home3Img = require("../assets/images/presale/HOME3.png");
// const video1 = require("../assets/images/presale/0.m4v");
const video2 = require("../assets/images/presale/H1_black.m4v");
const video3 = require("../assets/images/presale/H2_black1.mp4");
const video4 = require("../assets/images/presale/H3_black2.m4v");

const partner1 = require("../assets/images/presale/logo1.png");
const partner2 = require("../assets/images/presale/logo2.png");
const partner3 = require("../assets/images/presale/logo3.png");
const partner4 = require("../assets/images/presale/logo4.png");
const partner5 = require("../assets/images/presale/logo5.png");
const partner6 = require("../assets/images/presale/logo6.png");
const partner7 = require("../assets/images/presale/logo7.png");
const partner8 = require("../assets/images/presale/logo8.png");
const partner9 = require("../assets/images/presale/logo9.png");
const partner10 = require("../assets/images/presale/logo11.png");
const partner11 = require("../assets/images/presale/logo12.png");
const partner12 = require("../assets/images/presale/logo13.png");
const partner13 = require("../assets/images/presale/logo14.png");

const preSaleVideo = require("../assets/images/presale/starplug.m4v");
const preSaleImg = require("../assets/images/presale/plug_DeMain.png");
const appleImg = require("../assets/images/presale/app_store.png");
const googleImg = require("../assets/images/presale/google_play.png");
const grants1 = require("../assets/images/presale/Grants1.png");
const grants2 = require("../assets/images/presale/Grants2.png");
const grants3 = require("../assets/images/presale/Grants3.png");
const qrcode1 = require("../assets/images/presale/Android.png");
const qrcode2 = require("../assets/images/presale/AppleQR.png");

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
//
const { location = {} } = typeof window !== "undefined" ? window : {};
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port, // 如果不是 dva 2.0 请删除
      isPlayed: 0,
      priceAmount: 99,
      payType: 1,
      cartCount: 1 || localStorage.getItem('COUNT'),
      modalVisible: false,
      showCart: false,
      downloadVisible: false,
      downloadTitle: 'Android',
    };
  }
  componentDidMount = () => {
    let myVideo1 = document.getElementById("jzVideo1");
    let myVideo2 = document.getElementById("jzVideo2");
    let myVideo4 = document.getElementById("jzVideo4");
    let myVideo5 = document.getElementById("jzVideo5");
    const script = document.createElement('script');
    script.src = 'shop.js';
    script.async = true;
    document.getElementById('shopContent').appendChild(script);

    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const preOrder = urlParams.get('pre');
    console.log('pre', preOrder);
    console.log(preOrder != '1711372176574');
    if (preOrder != '1711372176574') {
      this.props.history.push("/")
    }

    if (!isMobile) {
      myVideo2.addEventListener("canplay", function (e) {
        myVideo2.play();
      });
      myVideo4.addEventListener("canplay", function (e) {
        myVideo4.play();
      });
      myVideo5.addEventListener("canplay", function (e) {
        myVideo5.play();
      });
    } else {
      setTimeout(() => {
        myVideo1.play();
        myVideo2.play();
        myVideo4.play();
        myVideo5.play();
      }, 500);
      myVideo2.addEventListener(
        "ended",
        function () {
          myVideo2.currentTime = 0.1;
          myVideo2.play();
        },
        false
      );
      myVideo4.addEventListener(
        "ended",
        function () {
          myVideo4.currentTime = 0.1;
          myVideo4.play();
        },
        false
      );
      myVideo5.addEventListener(
        "ended",
        function () {
          myVideo5.currentTime = 0.1;
          myVideo5.play();
        },
        false
      );
    }

    if (this.state.isPlayed === 0) {
      myVideo1.play();
      this.setState({
        isPlayed: this.state.isPlayed + 1,
      });
    }
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    if (location.port) {
      // setTimeout(() => {
      this.setState({
        show: true,
      });
      // }, 500);
    }
    window.scrollTo(0,0);
  }
  checkOut() {
    message.config({
      top: 500,
      duration: 2,
      maxCount: 3,
    });
    message.info(
      "Our product will be available in March! Thank you for your enthusiasm and interest. We can't wait to share it with you."
    );
  }
  numberChange = (value) => {
    message.config({
      top: 500,
      duration: 2,
      maxCount: 3,
    });
    if (value > 10) {
      message.info(
        "Purchase up to 10！"
      );
    }
    let val = value > 10 ? 10 : value;
    localStorage.setItem('COUNT', val);
    this.setState({
      priceAmount: parseInt(val) * 99,
      cartCount: val,
      showCart: true,
    });
    if (val == 0) {
      fixBtn.style.display = 'block';
      // fixCart.style.display = 'none';
      this.setState({
        showCart: false
      })
    }
  }
  changePayType = (e) => {
    this.setState({payType: e.target.value})
  }
  // click pre-order now
  confirmOrder = () => {
    this.setState({
      modalVisible: true,
      showCart: false
    })
  }
  openDownload = (type) => {
    this.setState({
      downloadVisible: true,
      downloadTitle: type
    })
  }
  cancelDownload() {
    this.setState({
      downloadVisible: false
    })
  }
  render() {
    const children = [];
    const radioStyle = {
      display: 'block',
      height: '30px',
      lineHeight: '30px',
    };
    const itemList = [
      {
        desc: '"Our electricity supply system was designed for fossil fuel generators - it\'s not well-suited for the energy sources of the future. That needs to change, and virtual power plants are a way to maximise the benefits of solar.”',
        name: 'Prof. Martin Green',
        position: 'Scientia Professor at UNSW',
        imgSrc: require('../assets/images/presale/Prof. Martin Green.png')
      },
      {
        desc: '"Starpower Vision, through its unique business model and technology, is enabling decarbonization down to the individuals. It\'s an amazing business and one that can potentially change the behavior of energy usage among consumers."',
        name: 'Kang Jen Wee',
        position: 'REDEX CEO',
        imgSrc: require('../assets/images/presale/Kang Jen Wee.png')
      },
      {
        desc: '"Starpower is a representative case of DePIN, bringing Web3 into the trillion dolar energy market. "',
        name: 'Dr. Xiao Feng',
        position: 'Chairman of Hashkey Group',
        imgSrc: require('../assets/images/presale/Dr. Xiao Feng.png')
      },
      {
        desc: '"Starpower is taking on climate change with Web3, and honestly, the last time I was this pumped was when I put my money into Ethereum."',
        name: 'Shen Bo',
        position: 'Founder of Fenbushi Capital',
        imgSrc: require('../assets/images/presale/Shen Bo.png')
      },
      {
        desc: '" IoTeX servers as the foundation for DePIN, and we are willing to collaborate with Starpower to address climate change and build an energy Internet. "',
        name: 'Raullen Chai',
        position: 'Founder of IoTeX',
        imgSrc: require('../assets/images/presale/Raullen Chai.png')
      },
      {
        desc: '"The Starpower team is ready to scale a global network of energy devices."',
        name: 'Salvador Gala',
        position: 'Founder of Escape Velocity',
        imgSrc: require('../assets/images/presale/Salvador Gala.png')
      }
    ];
    const productInfo = [
      {
        name: 'Dimensions',
        value: '62mm*56mm*110mm'
      },
      {
        name: 'Weight',
        value: '126g',
      },
      {
        name: 'Connectivity details',
        value: 'Bluetooth  / Wi-Fi with 2.4GHz'
      },
      {
        name: 'Input',
        value: '100-240VAC  50/60Hz'
      },
      {
        name: 'Max power',
        value: '1400VA at 110V / 220VA at 220V'
      },
      {
        name: 'Max current',
        value: '13A'
      }
    ]
    const bottomStyle = {
      borderBottom: '1px solid rgb(166, 166, 166)'
    }
    const faqList = [
      {
        title: "What's in the box?",
        conent: "Each Starplug box contains the Starplug smart device itself and a quick start guide to help you get it set up. Also included is information on how to download the Starpower app, which you'll use to manage your device and view your energy usage and rewards."
      },
      {
        title: "What is the price of Starplug?",
        conent: "Starplug starts at $109 plus taxes, shipping fees and duties for international orders."
      },
      {
        title: "Does it work in my region?",
        conent: "We understand that plug and socket standards vary across different countries and regions. To ensure that you can use your Starplug smart device right out of the box, we will automatically match and ship the plug type that is compatible with your shipping address."
      },
      {
        title: "Do you ship to my country?",
        conent: "We currently ship to countries in the European Union, North America, and the Asia-Pacific region. If you're unsure whether we ship to your specific location, please contact our support team support@starpower.world for more information."
      },
      {
        title: "When will my Starplug be shipped?",
        conent: "We expect to begin shipping Starplug devices from late April, depending on your region. You'll receive a confirmation email with your shipping details and a tracking number once your order is dispatched."
      },
      {
        title: "How do I cancel my order or return a product?",
        conent: [
          <span>You may cancel your order before it has been shipped, but please be aware that a cancellation fee of $30 will apply. To return a product, you must contact support@starpower.world within 14 days of receiving your shipment. Once you have indicated your intent to return, you will have an additional 14 days to return your products or arrange for their collection. Keep in mind, only products purchased directly from Starpower qualify for a return. Ensure the item is returned with all included accessories and packaging. A restocking fee of $30 per unit will also apply for returns, and you will be responsible for covering the shipping and any applicable VAT/duties. Refunds will be issued minus the shipping costs, cancellation fee, or restocking fee, using alternative methods since refunds cannot be processed back to the original payment method. Please note, activated devices are ineligible for return due to their unique digital wallet. See our Terms of Service for more information. </span>,
          <br/>,
          <span>For any additional questions or concerns, please don't hesitate to reach out to our support team support@starpower.world. We're here to help make your Starplug experience as smooth and rewarding as possible.</span>
        ]
      },
    ]
    let {payType} = this.state;
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <Modal
          title=""
          width={isMobile? '90%': '50%'}
          centered
          footer={null}
          visible={this.state.downloadVisible}
          onCancel={() => this.cancelDownload(false)}
        >
          <div className="download-modal">
            <p>Download for {this.state.downloadTitle} mobile</p>
            <img className="download-modal-logo" src={logoImg} alt="" />
            {this.state.downloadTitle == 'Apple'? (
              <img className="download-modal-qrcode" src={qrcode2} alt="" />
            ) : (
              <img className="download-modal-qrcode" src={qrcode1} alt="" />
            )}
            <p style={{fontWeight: 'bold'}}>Your new trusted companion</p>
            <p>Scan the QR  code  or search for Starpower in the App Store or Google Play soon.</p>
          </div>
        </Modal>
        <Modal
          title="Choose payment method"
          centered
          visible={this.state.modalVisible}
          onOk={() => this.setModalVisible(true)}
          onCancel={() => this.setModalVisible(false)}
        >
          <Radio.Group onChange={this.changePayType} value={payType} direction="vertical">
            <Radio style={radioStyle} value={1}>Card Pay</Radio>
            <Radio style={radioStyle} value={2}>Solana Pay</Radio>
          </Radio.Group>
        </Modal>
        <div className="presale-detial">
          {/* <Link to='/shop'>
            <div className="presale-fix-btn" id="presaleFixBtn">Pre-order Now</div>
          </Link> */}
          {/* <div className="presale-fix-cart" id="presaleFixCart">
            <div className="fix-cart-img"><img src={cartImg} alt="" /></div>
            <div className="fix-cart-num">
              <div>
                <InputNumber className="cart-num-input" size="large" min={0} max={10} value={this.state.cartCount} defaultValue={1} onChange={this.numberChange} />Starplug
              </div>
              <p>${this.state.priceAmount}</p>
            </div>
            <div className="fix-cart-buy" onClick={this.confirmOrder}>Pre-order</div>
          </div> */}
          <div className="presale-top-btn">
            <p className="presale-btn-title">Plug and Play from <span className="presale-title-price">$109</span></p>
            <p className="presale-btn-desc">
              Starplug launches ! Order now and begin saving earth/e-bill and
              earing $STAR token
            </p>
            <div></div>
            {/* <button className="presale-btn-button">Pre-order</button> */}
            <div className="presale-btn-div" id="shopContent">
              <div id='product-component-1711372176574'></div>
            </div>
            {/* <Link className="presale-btn-button" to="/shop">
              Pre-order Now
            </Link> */}
          </div>
          <div className="presale-floor" id="hideTop">
            <div className="presale-floor-video">
              <video
                className="floor-video-src"
                id="jzVideo1"
                muted
                playsInline
                x5-video-player-type="h5"
                src={video2}
                poster={home1Img}
              />
            </div>
          </div>
          <div className="presale-partner" id="presalePartner">
            <div className="presale-partner-banner">
              <div>
                <div className="presale-partner-list">
                  <div className="presale-partner-item">
                    <img src={partner1} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner2} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner3} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner4} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner5} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner6} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner7} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner8} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner9} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner10} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner11} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner12} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner13} alt="" />
                  </div>
                </div>
              </div>
              <div>
                <div className="presale-partner-list">
                  <div className="presale-partner-item">
                    <img src={partner1} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner2} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner3} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner4} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner5} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner6} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner7} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner8} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner9} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner10} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner11} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner12} alt="" />
                  </div>
                  <div className="presale-partner-item">
                    <img src={partner13} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="presale-floor">
            <div className="presale-floor-video">
              <video
                className="floor-video-src"
                id="jzVideo2"
                autoPlay
                playsInline
                loop
                muted
                x5-video-player-type="h5"
                src={video3}
                poster={home2Img}
              />
            </div>
            <p
              className="presale-floor-desc"
              style={{ top: isMobile ? "70%" : "65vh", color: "#FFF" }}
            >
              Powered by ESP32
            </p>
            <p className="presale-floor-desc2">offering robust connectivity and verifiability</p>
          </div>
          <div className="presale-floor">
            <div className="presale-floor-video">
              <video
                className="floor-video-src"
                id="jzVideo4"
                autoPlay
                playsInline
                loop
                muted
                x5-video-player-type="h5"
                src={video4}
                poster={home3Img}
              />
            </div>
            <p
              className="presale-floor-desc"
              style={{ top: isMobile ? "70%" : "65vh", color: "#FFF" }}
            >
              Earn crypto anywhere
            </p>
            <p className="presale-floor-desc2">
              connecting electrical appliances in your home, office, or factory
            </p>
          </div>
          <div className="presale-product" id="presaleProduct">
            <div className="presale-product-content">
              <div className="presale-product-title">Starplug specifications</div>
              <div className="presale-product-info">
                <div className="presale-info-img">
                  <video
                    className="presale-video-src"
                    id="jzVideo5"
                    autoPlay
                    playsInline
                    loop
                    muted
                    x5-video-player-type="h5"
                    src={preSaleVideo}
                    poster={preSaleImg}
                  />
                  <div className="presale-img-desc">*We auto-ship the right plug type for your location</div>
                </div>
                <div className="presale-info-detail">
                  {productInfo.map((item, index)=>{
                    return (
                      <div className="presale-detail-item" key={index} style={(index == productInfo.length - 1) ? bottomStyle  : null}>
                        <p>{item.name}</p>
                        <p>{item.value}</p>
                      </div>
                    )
                  })}
                  <div className="presale-detail-desc">
                    <p>ESP32 Chip technology offering robust connectivity and verifiability.</p>
                    <p>Dual-core, multithreaded for lightning response.</p>
                    <p>Wireless/Bluetooth, APP-driven STAR Token mining.</p>
                    <p>Unique device identity.</p>
                    <p>Monitor power use, remote control relays for trusted, seamless plug-and-play.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="presale-FAQ">
            <div className="presale-FAQ-content">
              <div className="presale-FAQ-title">Frequently Asked Questions (FAQs) for Starplug Pre-Order</div>
              <div className="presale-FAQ-list">
                {
                  faqList.map((item, index) => {
                    return (
                      <div className="presale-FAQ-item" key={index}>
                        <p className="presale-FAQ-Q">{item.title}</p>
                        <p className="presale-FAQ-A">{item.conent}</p>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          <div className="presale-download">
            <div className="presale-download-content">
              <div className="presale-download-title">
                <p>Download for Starpower mobile</p>
                <p>save our planet with climate technology.</p>
              </div>
              <div className="presale-download-btn">
                <img src={appleImg} alt="" onClick={()=>{this.openDownload('Apple')}}/>
                <img src={googleImg} alt="" onClick={()=>{this.openDownload('Android')}}/>
              </div>
              {isMobile ? (
                <Swiper
                  style={{ width: '100%' }}
                  className="presale-swiper"
                  spaceBetween={-80}
                  slidesPerView="auto"
                  centeredSlides={true}
                >
                  <SwiperSlide style={{ width: '80%' }}><img src={grants3} alt="" /></SwiperSlide>
                  <SwiperSlide style={{ width: '80%' }}><img src={grants2} alt="" /></SwiperSlide>
                  <SwiperSlide style={{ width: '80%' }}><img src={grants1} alt="" /></SwiperSlide>
                </Swiper>
              ) : (
                <div className="presale-download-info">
                  <img src={grants1} alt="" />
                  <img src={grants2} alt="" />
                  <img src={grants3} alt="" />
                </div>
              )}
              <p className="presale-download-bottom">Join the Starpower community to seamlessly integrate your electrical appliances through IoT technology, contribute to environmental sustainability, reduce your electricity costs, and earn carbon credits as rewards.</p>
            </div>
          </div>
          <div className="presale-testimonials">
            <div className="presale-testimonials-title">Testimonials</div>
            <div className="presale-testimonials-banner">
            <div className="presale-testimonials-loop">
              <div>
                <div className="presale-testimonials-list">
                  {itemList.map((item, index)=>{
                    return (
                      <div className="testimonials-list-item" key={index}>
                        <div className="testimonials-item-info">{item.desc}</div>
                        <div className="testimonials-item-person">
                          <img src={item.imgSrc} alt="" />
                          <div className="item-person-detail">
                            <p>{item.name}</p>
                            <p>{item.position}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div>
                <div className="presale-testimonials-list">
                  {itemList.map((item, index)=>{
                    return (
                      <div className="testimonials-list-item" key={index}>
                        <div className="testimonials-item-info">{item.desc}</div>
                        <div className="testimonials-item-person">
                          <img src={item.imgSrc} alt=""/>
                          <div className="item-person-detail">
                            <p>{item.name}</p>
                            <p>{item.position}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
        {this.state.show && children}
      </div>
    );
  }
}
