import React, { memo, useEffect } from "react";
import { TopHead } from "./style";
import { Modal } from "antd";
import { enquireScreen } from "enquire-js";
const screenwidth = window.innerWidth;
const qrcode1 = require("@/assets/images/presale/Android.png");
const qrcode2 = require("@/assets/images/presale/AppleQR.png");
const qrcode3 = require("@/assets/images/presale/galaxy.png");
let isMobile;
if (window.innerWidth < 1366) {
  isMobile = true;
} else {
  enquireScreen((b) => {
    isMobile = b;
  });
}
let downloadVisible = false;
let openDownload = () => {
  console.log(11111);
  // downloadVisible = true;
};

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadVisible: false,
      downloadTitle: "Android",
    };
  }
  openDownload = () => {
    this.setState({
      downloadVisible: true,
      downloadTitle: "Starpower",
    });
  };
  cancelDownload() {
    this.setState({
      downloadVisible: false,
    });
  }
  render() {
    return (
      <TopHead screenwidth={screenwidth}>
        <div className="title">
          <span className="normal-text">GM!⚡️ How can we </span>
          <span className="gradient-text"> help ？</span>
        </div>
        <div className="top-list">
          <div className="top-list-item" onClick={() => {this.openDownload("ALL");}}>
            <img
              className="img"
              src={require(`@/assets/images/userguide/icon1.png`)}
              alt="img"
            />
            <p className="list-item1">Getting Started</p>
            <p className="list-item2">
              Download Starpower dAPP to save on bills and earn STARs!
            </p>
          </div>
          <a href="https://discord.gg/starpowernetwork" target="_blank">
            <div className="top-list-item">
              <img
                className="img"
                src={require(`@/assets/images/userguide/icon2.png`)}
                alt="img"
              />
              <p className="list-item1">Join our Discord</p>
              <p className="list-item2">
                Find answers by searching in the community or asking other users
                and admins
              </p>
            </div>
          </a>
          <div className="top-list-item">
            <img
              className="img"
              src={require(`@/assets/images/userguide/icon3.png`)}
              alt="img"
            />
            <p className="list-item1">Email Us</p>
            <p className="list-item2">
              If you can't find the answer by searching, contact us at
              support@starpower.world
            </p>
          </div>
        </div>
        <Modal
            title=""
            width={isMobile ? "" : "50%"}
            centered
            mask={true}
            maskStyle={{ backgroundColor: "#000", opacity: "0.9" }}
            footer={null}
            visible={this.state.downloadVisible}
            onCancel={() => this.cancelDownload(false)}
            className="download-modal"
            zIndex="9999"
          >
            <div className="download-modal-content">
              <p>
                Download for{" "}
                <span className="download-modal-title">
                  Starpower
                </span>{" "}
                mobile
              </p>
              <div className="download-qrcode-list">
                <div className="download-qrcode-item">
                  <img className="download-modal-qrcode" src={qrcode2} alt="" />
                  <p>iOS</p>
                </div>
                <div className="download-qrcode-item">
                  <img className="download-modal-qrcode" src={qrcode1} alt="" />
                  <p>Android</p>
                </div>
                <div className="download-qrcode-item">
                  <img className="download-modal-qrcode" src={qrcode3} alt="" />
                  <p>Galaxystore</p>
                </div>
              </div>
              <p style={{ fontWeight: "bold" }}>Your new trusted companion</p>
              <p>
                Scan the QR code or search for Starpower in the App Store or
                Google Play soon.
              </p>
            </div>
          </Modal>
      </TopHead>
    );
  }
}
export default Header;
